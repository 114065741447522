
import {checkAuthLoader } from '../util/auth'

import { redirect } from 'react-router-dom';
import HomeContent from "../components/HomeContent";

const Home = () => {  
  return (
    <HomeContent />
  );
}

export default Home;

export const loader = async () => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return null;
  }
  return redirect('/dashboard');
}